.index-body header .header-placeholder {
  height: 0;
}

.index-banner {
  overflow: hidden;
  box-sizing: border-box;
  background-color: #fabe00;
  padding-top: 110px;
  height: 100vh;
}

.index-banner .swiper-pagination-bullet:only-child {
  display: block !important;
}

@media (max-width: 1600px) {
  .index-banner {
    padding-top: 100px;
  }
}

@media (max-width: 1366px) {
  .index-banner {
    padding-top: 90px;
  }
}

@media (max-width: 1024px) {
  .index-banner {
    padding-top: 80px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    padding-top: 70px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    padding-top: 60px;
  }
}

@media (max-width: 1024px) {
  .index-banner {
    height: 500px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    height: 260px;
  }
}

.index-banner .banner-cont {
  height: 100%;
  position: relative;
}

.index-banner .banner-cont .swiper-container {
  height: 100%;
  overflow: hidden;
  width: 76.82%;
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-container {
    width: 100%;
  }
}

.index-banner .banner-cont .swiper-container .swiper-slide {
  overflow: hidden;
}

.index-banner .banner-cont .swiper-container .swiper-slide.swiper-slide-active .slide-inner b, .index-banner .banner-cont .swiper-container .swiper-slide.swiper-slide-duplicate-active .slide-inner b {
  transform: scale(1);
}

.index-banner .banner-cont .swiper-container .swiper-slide .slide-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.index-banner .banner-cont .swiper-container .swiper-slide .slide-inner b {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .8s;
  transition-delay: .2s;
}

.index-banner .banner-cont .swiper-container .swiper-slide .slide-inner .video {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.index-banner .banner-cont .swiper-container .swiper-slide .slide-inner .video video {
  width: auto;
  height: 140%;
}

.index-banner .banner-cont .swiper-container .swiper-slide .slide-inner::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}

.index-banner .banner-cont .swiper-pagination {
  height: 100%;
  top: 0;
  bottom: auto;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 26px;
  right: 40px;
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination {
    width: 10px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination {
    right: 10px;
  }
}

.index-banner .banner-cont .swiper-pagination::after {
  content: '';
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 12px;
  z-index: -1;
  display: block;
  left: 12px;
  background-color: #000;
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination::after {
    display: none;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination::after {
    left: 4px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination::after {
    background-color: #fabe00;
  }
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  background: #fabe00;
  position: relative;
  box-sizing: border-box;
  margin-left: 1px;
  width: 26px;
  height: 26px;
  border: 1px solid #0d0a00;
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet {
    width: 8px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet {
    height: 8px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet {
    border: 1px solid #fabe00;
  }
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
  margin-bottom: 28px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 24px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 8px;
  }
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:hover::after {
  transform: scale(1.6);
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background: #0d0a00;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - 3px);
  transition: all .3s;
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  background: #0d0a00;
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
    background: #fabe00;
  }
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active svg circle {
  animation: 6s circle linear;
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet svg {
  width: 28px;
  height: 28px;
  position: absolute;
  top: -2px;
  left: -2px;
  z-index: 1;
  transform-origin: center;
  transform-box: fill-box;
  transform: rotate(-90deg);
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet svg {
    display: none;
  }
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet svg circle {
  stroke-width: 3;
  fill: none;
  stroke: #000;
  stroke-dasharray: 76px;
  stroke-dashoffset: 76px;
}

.index-banner .banner-cont .index-banner-text {
  height: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 2;
  text-align: right;
}

.index-banner .banner-cont .index-banner-text .text-item {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
  width: 100%;
}

.index-banner .banner-cont .index-banner-text .text-item .title1 {
  font-family: 'lato-black';
  position: relative;
  white-space: nowrap;
  font-weight: bold;
  font-size: 80px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 70px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 60px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 50px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 30px;
  }
}

.index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow {
  color: #fabe00;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15%;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow {
    padding-right: 14.4%;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow {
    padding-right: 13.8%;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow {
    padding-right: 13.2%;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow {
    padding-right: 12.6%;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow {
    padding-right: 12%;
  }
}

.index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow a {
  display: flex;
  align-items: center;
  margin-right: 35px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow a {
    margin-right: 30px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow a {
    margin-right: 25px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow a {
    margin-right: 20px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow a {
    margin-right: 15px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow a {
    margin-right: 10px;
  }
}

.index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow a .iconfont {
  font-size: 80px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow a .iconfont {
    font-size: 69.2px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow a .iconfont {
    font-size: 58.4px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow a .iconfont {
    font-size: 47.6px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow a .iconfont {
    font-size: 36.8px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-yellow a .iconfont {
    font-size: 26px;
  }
}

.index-banner .banner-cont .index-banner-text .text-item .title1 .title-black {
  color: #000;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  width: 23.18%;
  padding-right: 15%;
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-black {
    width: 0;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-black {
    padding-right: 14.4%;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-black {
    padding-right: 13.8%;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-black {
    padding-right: 13.2%;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-black {
    padding-right: 12.6%;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 .title-black {
    padding-right: 12%;
  }
}

.index-banner .banner-cont .index-banner-text .text-item .title2 {
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 70px;
  padding-right: 160px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 60px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 50px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    padding-right: 138px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    padding-right: 116px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    padding-right: 94px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    padding-right: 72px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    padding-right: 50px;
  }
}

@keyframes circle {
  0% {
    stroke-dashoffset: 76px;
  }
  50% {
    stroke-dashoffset: 38px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}

.index-common-title {
  color: #000;
  font-family: 'lato-black';
  font-size: 60px;
  margin-top: 110px;
  margin-bottom: 60px;
}

@media (max-width: 1600px) {
  .index-common-title {
    font-size: 54px;
  }
}

@media (max-width: 1366px) {
  .index-common-title {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  .index-common-title {
    font-size: 42px;
  }
}

@media (max-width: 991px) {
  .index-common-title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .index-common-title {
    font-size: 30px;
  }
}

@media (max-width: 1600px) {
  .index-common-title {
    margin-top: 92px;
  }
}

@media (max-width: 1366px) {
  .index-common-title {
    margin-top: 74px;
  }
}

@media (max-width: 1024px) {
  .index-common-title {
    margin-top: 56px;
  }
}

@media (max-width: 991px) {
  .index-common-title {
    margin-top: 38px;
  }
}

@media (max-width: 767px) {
  .index-common-title {
    margin-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-common-title {
    margin-bottom: 52px;
  }
}

@media (max-width: 1366px) {
  .index-common-title {
    margin-bottom: 44px;
  }
}

@media (max-width: 1024px) {
  .index-common-title {
    margin-bottom: 36px;
  }
}

@media (max-width: 991px) {
  .index-common-title {
    margin-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .index-common-title {
    margin-bottom: 20px;
  }
}

.index-product .product-tabs {
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-start;
  overflow-x: auto;
  font-weight: bold;
  color: #515151;
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  padding-bottom: 20px;
  margin-top: 0;
}

@media (max-width: 1600px) {
  .index-product .product-tabs {
    margin-bottom: 16px;
  }
}

@media (max-width: 1366px) {
  .index-product .product-tabs {
    margin-bottom: 12px;
  }
}

@media (max-width: 1024px) {
  .index-product .product-tabs {
    margin-bottom: 8px;
  }
}

@media (max-width: 991px) {
  .index-product .product-tabs {
    margin-bottom: 4px;
  }
}

@media (max-width: 767px) {
  .index-product .product-tabs {
    margin-bottom: 0px;
  }
}

@media (max-width: 1600px) {
  .index-product .product-tabs {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index-product .product-tabs {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index-product .product-tabs {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index-product .product-tabs {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index-product .product-tabs {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-product .product-tabs {
    padding-bottom: 17px;
  }
}

@media (max-width: 1366px) {
  .index-product .product-tabs {
    padding-bottom: 14px;
  }
}

@media (max-width: 1024px) {
  .index-product .product-tabs {
    padding-bottom: 11px;
  }
}

@media (max-width: 991px) {
  .index-product .product-tabs {
    padding-bottom: 8px;
  }
}

@media (max-width: 767px) {
  .index-product .product-tabs {
    padding-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .index-product .product-tabs {
    margin-top: -15px;
  }
}

.index-product .product-tabs .tab-item {
  cursor: pointer;
  transition: all .25s;
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding-top: 10px;
}

@media (max-width: 1600px) {
  .index-product .product-tabs .tab-item {
    margin-right: 9px;
  }
}

@media (max-width: 1366px) {
  .index-product .product-tabs .tab-item {
    margin-right: 8px;
  }
}

@media (max-width: 1024px) {
  .index-product .product-tabs .tab-item {
    margin-right: 7px;
  }
}

@media (max-width: 991px) {
  .index-product .product-tabs .tab-item {
    margin-right: 6px;
  }
}

@media (max-width: 767px) {
  .index-product .product-tabs .tab-item {
    margin-right: 5px;
  }
}

@media (max-width: 1600px) {
  .index-product .product-tabs .tab-item {
    padding-top: 8px;
  }
}

@media (max-width: 1366px) {
  .index-product .product-tabs .tab-item {
    padding-top: 6px;
  }
}

@media (max-width: 1024px) {
  .index-product .product-tabs .tab-item {
    padding-top: 4px;
  }
}

@media (max-width: 991px) {
  .index-product .product-tabs .tab-item {
    padding-top: 2px;
  }
}

@media (max-width: 767px) {
  .index-product .product-tabs .tab-item {
    padding-top: 0px;
  }
}

.index-product .product-tabs .tab-item:not(:first-child) {
  margin-left: 30px;
}

@media (max-width: 1600px) {
  .index-product .product-tabs .tab-item:not(:first-child) {
    margin-left: 25px;
  }
}

@media (max-width: 1366px) {
  .index-product .product-tabs .tab-item:not(:first-child) {
    margin-left: 20px;
  }
}

@media (max-width: 1024px) {
  .index-product .product-tabs .tab-item:not(:first-child) {
    margin-left: 15px;
  }
}

@media (max-width: 991px) {
  .index-product .product-tabs .tab-item:not(:first-child) {
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .index-product .product-tabs .tab-item:not(:first-child) {
    margin-left: 5px;
  }
}

.index-product .product-tabs .tab-item:not(.active):hover {
  color: #fabe00;
}

.index-product .product-tabs .tab-item::before {
  font-family: 'iconfont';
  font-weight: lighter;
  color: #c0c0c0;
  transition: all;
  margin-right: 5px;
  font-size: 40px;
}

@media (max-width: 1600px) {
  .index-product .product-tabs .tab-item::before {
    margin-right: 4.4px;
  }
}

@media (max-width: 1366px) {
  .index-product .product-tabs .tab-item::before {
    margin-right: 3.8px;
  }
}

@media (max-width: 1024px) {
  .index-product .product-tabs .tab-item::before {
    margin-right: 3.2px;
  }
}

@media (max-width: 991px) {
  .index-product .product-tabs .tab-item::before {
    margin-right: 2.6px;
  }
}

@media (max-width: 767px) {
  .index-product .product-tabs .tab-item::before {
    margin-right: 2px;
  }
}

@media (max-width: 1600px) {
  .index-product .product-tabs .tab-item::before {
    font-size: 35.6px;
  }
}

@media (max-width: 1366px) {
  .index-product .product-tabs .tab-item::before {
    font-size: 31.2px;
  }
}

@media (max-width: 1024px) {
  .index-product .product-tabs .tab-item::before {
    font-size: 26.8px;
  }
}

@media (max-width: 991px) {
  .index-product .product-tabs .tab-item::before {
    font-size: 22.4px;
  }
}

@media (max-width: 767px) {
  .index-product .product-tabs .tab-item::before {
    font-size: 18px;
  }
}

.index-product .product-tabs .tab-item:nth-child(1)::before {
  content: '\e603';
}

.index-product .product-tabs .tab-item:nth-child(2)::before {
  content: '\e608';
}

.index-product .product-tabs .tab-item:nth-child(3)::before {
  content: '\e60a';
}

.index-product .product-tabs .tab-item:nth-child(4)::before {
  content: '\e60b';
}

.index-product .product-tabs .tab-item:nth-child(5)::before {
  content: '\e604';
}

.index-product .product-tabs .tab-item:nth-child(6)::before {
  content: '\e60d';
}

.index-product .product-tabs .tab-item span {
  position: relative;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 1600px) {
  .index-product .product-tabs .tab-item span {
    padding-top: 10px;
  }
}

@media (max-width: 1366px) {
  .index-product .product-tabs .tab-item span {
    padding-top: 10px;
  }
}

@media (max-width: 1024px) {
  .index-product .product-tabs .tab-item span {
    padding-top: 10px;
  }
}

@media (max-width: 991px) {
  .index-product .product-tabs .tab-item span {
    padding-top: 10px;
  }
}

@media (max-width: 767px) {
  .index-product .product-tabs .tab-item span {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-product .product-tabs .tab-item span {
    padding-bottom: 10px;
  }
}

@media (max-width: 1366px) {
  .index-product .product-tabs .tab-item span {
    padding-bottom: 10px;
  }
}

@media (max-width: 1024px) {
  .index-product .product-tabs .tab-item span {
    padding-bottom: 10px;
  }
}

@media (max-width: 991px) {
  .index-product .product-tabs .tab-item span {
    padding-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .index-product .product-tabs .tab-item span {
    padding-bottom: 10px;
  }
}

.index-product .product-tabs .tab-item span::after {
  content: '';
  display: block;
  width: 0;
  background-color: #fabe00;
  position: absolute;
  bottom: 10px;
  left: 0;
  z-index: -1;
  transition: all .3s;
  height: 10px;
}

@media (max-width: 1600px) {
  .index-product .product-tabs .tab-item span::after {
    height: 9px;
  }
}

@media (max-width: 1366px) {
  .index-product .product-tabs .tab-item span::after {
    height: 8px;
  }
}

@media (max-width: 1024px) {
  .index-product .product-tabs .tab-item span::after {
    height: 7px;
  }
}

@media (max-width: 991px) {
  .index-product .product-tabs .tab-item span::after {
    height: 6px;
  }
}

@media (max-width: 767px) {
  .index-product .product-tabs .tab-item span::after {
    height: 5px;
  }
}

.index-product .product-tabs .tab-item.active::before {
  color: #fabe00;
}

.index-product .product-tabs .tab-item.active span::after {
  width: 100%;
}

.index-product .product-tabs.cn-tabs .tab-item:nth-child(1)::before {
  content: '\e608';
}

.index-product .product-tabs.cn-tabs .tab-item:nth-child(2)::before {
  content: '\e60a';
}

.index-product .product-tabs.cn-tabs .tab-item:nth-child(3)::before {
  content: '\e60b';
}

.index-product .product-tabs.cn-tabs .tab-item:nth-child(4)::before {
  content: '\e604';
}

.index-product .product-tabs.cn-tabs .tab-item:nth-child(5)::before {
  content: '\e60d';
}

.index-product .product-swiper {
  margin-left: -20px;
  margin-right: -20px;
}

.index-product .product-swiper .swiper {
  padding: 20px;
  padding-top: 20px;
  padding-bottom: 100px;
}

@media (max-width: 767px) {
  .index-product .product-swiper .swiper {
    padding-top: 10px;
  }
}

@media (max-width: 1024px) {
  .index-product .product-swiper .swiper {
    padding-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .index-product .product-swiper .swiper {
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .index-product .product-swiper .swiper {
    padding-bottom: 10px;
  }
}

.index-search {
  background-color: #fabe00;
  position: relative;
}

.index-search .fixed-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80%;
  height: 138px;
  z-index: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;
}

.index-search .fixed-img img {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
}

.index-search .container {
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  display: flex;
}

@media (max-width: 767px) {
  .index-search .container {
    display: block;
  }
}

.index-search .container .search-left {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 65%;
  padding-top: 120px;
  padding-bottom: 110px;
}

@media (max-width: 1024px) {
  .index-search .container .search-left {
    width: 48%;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-left {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index-search .container .search-left {
    padding-top: 100px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-left {
    padding-top: 80px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-left {
    padding-top: 60px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left {
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-left {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .search-left {
    padding-bottom: 98px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-left {
    padding-bottom: 86px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-left {
    padding-bottom: 74px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left {
    padding-bottom: 62px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-left {
    padding-bottom: 50px;
  }
}

.index-search .container .search-left .form-title {
  font-family: 'lato-black';
  font-size: 32px;
  margin-bottom: 25px;
}

@media (max-width: 1600px) {
  .index-search .container .search-left .form-title {
    font-size: 28.8px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-left .form-title {
    font-size: 25.6px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-left .form-title {
    font-size: 22.4px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-title {
    font-size: 19.2px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-left .form-title {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .search-left .form-title {
    margin-bottom: 23px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-left .form-title {
    margin-bottom: 21px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-left .form-title {
    margin-bottom: 19px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-title {
    margin-bottom: 17px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-left .form-title {
    margin-bottom: 15px;
  }
}

.index-search .container .search-left .form-radio {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .index-search .container .search-left .form-radio {
    margin-bottom: 26px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-left .form-radio {
    margin-bottom: 22px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-left .form-radio {
    margin-bottom: 18px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-radio {
    margin-bottom: 14px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-left .form-radio {
    margin-bottom: 10px;
  }
}

.index-search .container .search-left .form-radio label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  width: 16%;
  margin-bottom: 20px;
}

@media (max-width: 1366px) {
  .index-search .container .search-left .form-radio label {
    width: 25%;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-left .form-radio label {
    width: 33.33%;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-left .form-radio label {
    width: 50%;
  }
}

@media (max-width: 1600px) {
  .index-search .container .search-left .form-radio label {
    margin-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-left .form-radio label {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-left .form-radio label {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-radio label {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-left .form-radio label {
    margin-bottom: 10px;
  }
}

.index-search .container .search-left .form-radio label input {
  opacity: 0;
  margin-right: 15px;
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-radio label input {
    margin-right: 10px;
  }
}

.index-search .container .search-left .form-radio label span::before {
  content: '';
  display: block;
  border-radius: 50%;
  border: 1px solid #000000;
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-radio label span::before {
    width: 16px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-radio label span::before {
    height: 16px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-radio label span::before {
    top: calc(50% - 8px);
  }
}

.index-search .container .search-left .form-radio label span::after {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  left: 4px;
  opacity: 0;
  transition: all .25s;
  width: 12px;
  height: 12px;
  top: calc(50% - 6px);
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-radio label span::after {
    width: 8px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-radio label span::after {
    height: 8px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-radio label span::after {
    top: calc(50% - 4px);
  }
}

.index-search .container .search-left .form-radio label input:checked + span::after {
  opacity: 1;
}

.index-search .container .search-left .form-btn .search-btn {
  display: inline-block;
  background-color: #000;
  color: #fabe00;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all .3s;
  border: 1px solid #000;
  font-family: 'lato-black';
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 85px;
  padding-right: 85px;
}

@media (max-width: 1600px) {
  .index-search .container .search-left .form-btn .search-btn {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-left .form-btn .search-btn {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-left .form-btn .search-btn {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-btn .search-btn {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-left .form-btn .search-btn {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-top: 18px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-top: 16px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-top: 14px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-top: 12px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-left: 74px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-left: 63px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-left: 52px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-left: 41px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-left: 30px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-right: 74px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-right: 63px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-right: 52px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-right: 41px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-left .form-btn .search-btn {
    padding-right: 30px;
  }
}

.index-search .container .search-left .form-btn .search-btn:hover {
  background-color: #fabe00;
  color: #000;
}

.index-search .container .search-right {
  flex-shrink: 0;
  position: relative;
  margin-top: -250px;
  width: 33.33%;
}

@media (max-width: 1600px) {
  .index-search .container .search-right {
    margin-top: -210px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-right {
    margin-top: -180px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-right {
    margin-top: -160px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-right {
    margin-top: -100px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-right {
    margin-top: 0px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-right {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-right {
    width: 100%;
  }
}

.index-search .container .search-right .bg-img {
  position: absolute;
  top: 0;
  height: 100%;
  width: 130%;
  left: 0;
}

@media (max-width: 767px) {
  .index-search .container .search-right .bg-img {
    left: -15%;
  }
}

.index-search .container .search-right .bg-img::after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.68);
}

.index-search .container .search-right .bg-img b {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.index-search .container .search-right .cont {
  display: block;
  position: relative;
  z-index: 2;
  color: #fabe00;
  padding-top: 180px;
  padding-bottom: 30px;
  padding-left: 95px;
}

@media (max-width: 1600px) {
  .index-search .container .search-right .cont {
    padding-top: 156px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-right .cont {
    padding-top: 132px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-right .cont {
    padding-top: 108px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-right .cont {
    padding-top: 84px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-right .cont {
    padding-top: 60px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .search-right .cont {
    padding-bottom: 26px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-right .cont {
    padding-bottom: 22px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-right .cont {
    padding-bottom: 18px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-right .cont {
    padding-bottom: 14px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-right .cont {
    padding-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .search-right .cont {
    padding-left: 80px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-right .cont {
    padding-left: 65px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-right .cont {
    padding-left: 50px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-right .cont {
    padding-left: 35px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-right .cont {
    padding-left: 20px;
  }
}

.index-search .container .search-right .cont .text {
  line-height: .95;
}

.index-search .container .search-right .cont .text::before {
  content: '';
  display: block;
  background-color: #fabe00;
  width: 100px;
  height: 4px;
  margin-bottom: 45px;
}

@media (max-width: 1600px) {
  .index-search .container .search-right .cont .text::before {
    width: 90px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-right .cont .text::before {
    width: 80px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-right .cont .text::before {
    width: 70px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-right .cont .text::before {
    width: 60px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-right .cont .text::before {
    width: 50px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .search-right .cont .text::before {
    height: 3.6px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-right .cont .text::before {
    height: 3.2px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-right .cont .text::before {
    height: 2.8px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-right .cont .text::before {
    height: 2.4px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-right .cont .text::before {
    height: 2px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .search-right .cont .text::before {
    margin-bottom: 40px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-right .cont .text::before {
    margin-bottom: 35px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-right .cont .text::before {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-right .cont .text::before {
    margin-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-right .cont .text::before {
    margin-bottom: 20px;
  }
}

.index-search .container .search-right .cont .text h1 {
  font-family: 'lato-black';
  font-size: 46px;
}

@media (max-width: 1600px) {
  .index-search .container .search-right .cont .text h1 {
    font-size: 41.2px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-right .cont .text h1 {
    font-size: 36.4px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-right .cont .text h1 {
    font-size: 31.6px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-right .cont .text h1 {
    font-size: 26.8px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-right .cont .text h1 {
    font-size: 22px;
  }
}

.index-search .container .search-right .cont .text h2 {
  font-family: 'lato-black';
  font-size: 60px;
}

@media (max-width: 1600px) {
  .index-search .container .search-right .cont .text h2 {
    font-size: 54px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-right .cont .text h2 {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-right .cont .text h2 {
    font-size: 42px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-right .cont .text h2 {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-right .cont .text h2 {
    font-size: 30px;
  }
}

.index-search .container .search-right .cont .icon {
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
}

@media (max-width: 1600px) {
  .index-search .container .search-right .cont .icon {
    margin-top: 52px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-right .cont .icon {
    margin-top: 44px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-right .cont .icon {
    margin-top: 36px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-right .cont .icon {
    margin-top: 28px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-right .cont .icon {
    margin-top: 20px;
  }
}

.index-search .container .search-right .cont .icon .iconfont {
  font-size: 170px;
  transform: translateX(30%);
}

@media (max-width: 1600px) {
  .index-search .container .search-right .cont .icon .iconfont {
    font-size: 152px;
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-right .cont .icon .iconfont {
    font-size: 134px;
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-right .cont .icon .iconfont {
    font-size: 116px;
  }
}

@media (max-width: 991px) {
  .index-search .container .search-right .cont .icon .iconfont {
    font-size: 98px;
  }
}

@media (max-width: 767px) {
  .index-search .container .search-right .cont .icon .iconfont {
    font-size: 80px;
  }
}

@media (max-width: 1600px) {
  .index-search .container .search-right .cont .icon .iconfont {
    transform: translateX(20%);
  }
}

@media (max-width: 1366px) {
  .index-search .container .search-right .cont .icon .iconfont {
    transform: translateX(10%);
  }
}

@media (max-width: 1024px) {
  .index-search .container .search-right .cont .icon .iconfont {
    transform: translateX(0);
  }
}

.index-about {
  position: relative;
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 130px;
}

@media (max-width: 1600px) {
  .index-about {
    padding-top: 66px;
  }
}

@media (max-width: 1366px) {
  .index-about {
    padding-top: 52px;
  }
}

@media (max-width: 1024px) {
  .index-about {
    padding-top: 38px;
  }
}

@media (max-width: 991px) {
  .index-about {
    padding-top: 24px;
  }
}

@media (max-width: 767px) {
  .index-about {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about {
    padding-bottom: 110px;
  }
}

@media (max-width: 1366px) {
  .index-about {
    padding-bottom: 90px;
  }
}

@media (max-width: 1024px) {
  .index-about {
    padding-bottom: 70px;
  }
}

@media (max-width: 991px) {
  .index-about {
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .index-about {
    padding-bottom: 30px;
  }
}

.index-about .fixed-img {
  position: absolute;
  top: 0;
  right: 0;
  width: 80%;
  height: 138px;
  z-index: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  transform: translateY(-100%);
}

.index-about .fixed-img img {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
}

.index-about .about-cont {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .index-about .about-cont {
    display: block;
  }
}

.index-about .about-cont .cont-img {
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  width: 40%;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-img {
    margin-bottom: 20px;
  }
}

.index-about .about-cont .cont-img b {
  display: block;
  width: 100%;
  height: 200%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: -70%;
  left: 0;
  z-index: 0;
}

.index-about .about-cont .cont-img .img-text {
  position: relative;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
  padding-top: 45px;
  padding-bottom: 45px;
  padding-left: 100px;
  padding-right: 10px;
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-img .img-text {
    padding-top: 40px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-img .img-text {
    padding-top: 35px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-img .img-text {
    padding-top: 30px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-img .img-text {
    padding-top: 25px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-img .img-text {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-img .img-text {
    padding-bottom: 40px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-img .img-text {
    padding-bottom: 35px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-img .img-text {
    padding-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-img .img-text {
    padding-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-img .img-text {
    padding-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-img .img-text {
    padding-left: 82px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-img .img-text {
    padding-left: 64px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-img .img-text {
    padding-left: 46px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-img .img-text {
    padding-left: 28px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-img .img-text {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-img .img-text {
    padding-right: 10px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-img .img-text {
    padding-right: 10px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-img .img-text {
    padding-right: 10px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-img .img-text {
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-img .img-text {
    padding-right: 10px;
  }
}

.index-about .about-cont .cont-img .img-text .item {
  color: #fff;
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-img .img-text .item {
    text-align: center;
  }
}

.index-about .about-cont .cont-img .img-text .item:not(:last-child) {
  margin-bottom: 36px;
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-img .img-text .item:not(:last-child) {
    margin-bottom: 32.8px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-img .img-text .item:not(:last-child) {
    margin-bottom: 29.6px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-img .img-text .item:not(:last-child) {
    margin-bottom: 26.4px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-img .img-text .item:not(:last-child) {
    margin-bottom: 23.2px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-img .img-text .item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.index-about .about-cont .cont-img .img-text .item h1 {
  font-family: 'lato-black';
  font-size: 80px;
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-img .img-text .item h1 {
    font-size: 72px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-img .img-text .item h1 {
    font-size: 64px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-img .img-text .item h1 {
    font-size: 56px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-img .img-text .item h1 {
    font-size: 48px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-img .img-text .item h1 {
    font-size: 40px;
  }
}

.index-about .about-cont .cont-img .img-text .item p {
  font-weight: bold;
}

.index-about .about-cont .cont-text {
  flex-shrink: 0;
  width: 50%;
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text {
    width: 55%;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text {
    width: 100%;
  }
}

.index-about .about-cont .cont-text .text-title {
  font-weight: bold;
  font-size: 56px;
  margin-bottom: 60px;
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-title {
    font-size: 49.2px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-title {
    font-size: 42.4px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-title {
    font-size: 35.6px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-title {
    font-size: 28.8px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-title {
    font-size: 22px;
  }
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-title {
    margin-bottom: 52px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-title {
    margin-bottom: 44px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-title {
    margin-bottom: 36px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-title {
    margin-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-title {
    margin-bottom: 20px;
  }
}

.index-about .about-cont .cont-text .text-t {
  padding-left: 105px;
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t {
    padding-left: 86px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t {
    padding-left: 67px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t {
    padding-left: 48px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t {
    padding-left: 29px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t {
    padding-left: 10px;
  }
}

.index-about .about-cont .cont-text .text-t h1 {
  color: #fabe00;
  font-weight: bold;
  position: relative;
  font-style: italic;
  margin-bottom: 35px;
  font-size: 50px;
  padding-left: 50px;
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t h1 {
    margin-bottom: 31px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t h1 {
    margin-bottom: 27px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t h1 {
    margin-bottom: 23px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t h1 {
    margin-bottom: 19px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t h1 {
    margin-bottom: 15px;
  }
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t h1 {
    font-size: 45px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t h1 {
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t h1 {
    font-size: 35px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t h1 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t h1 {
    font-size: 25px;
  }
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t h1 {
    padding-left: 46px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t h1 {
    padding-left: 42px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t h1 {
    padding-left: 38px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t h1 {
    padding-left: 34px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t h1 {
    padding-left: 30px;
  }
}

.index-about .about-cont .cont-text .text-t h1 img {
  height: auto;
  width: 34px;
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t h1 img {
    width: 31.2px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t h1 img {
    width: 28.4px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t h1 img {
    width: 25.6px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t h1 img {
    width: 22.8px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t h1 img {
    width: 20px;
  }
}

.index-about .about-cont .cont-text .text-t h1 img.left-yh {
  position: absolute;
  top: 0;
  left: 0;
}

.index-about .about-cont .cont-text .text-t h1 img.right-yh {
  transform: rotate(180deg);
  margin-left: 22px;
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t h1 img.right-yh {
    margin-left: 19.6px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t h1 img.right-yh {
    margin-left: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t h1 img.right-yh {
    margin-left: 14.8px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t h1 img.right-yh {
    margin-left: 12.4px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t h1 img.right-yh {
    margin-left: 10px;
  }
}

.index-about .about-cont .cont-text .text-t .t {
  text-align: justify;
  text-justify: inter-ideograph;
  /*IE*/
  line-height: 2;
  margin-bottom: 70px;
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t .t {
    text-align: left;
  }
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t .t {
    line-height: 1.92;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t .t {
    line-height: 1.84;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t .t {
    line-height: 1.76;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t .t {
    line-height: 1.68;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t .t {
    line-height: 1.6;
  }
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t .t {
    margin-bottom: 60px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t .t {
    margin-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t .t {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t .t {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t .t {
    margin-bottom: 20px;
  }
}

.index-about .about-cont .cont-text .text-t a {
  display: inline-block;
  background-color: #fabe00;
  position: relative;
  transition: all .3s;
  font-size: 18px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t a {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t a {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t a {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t a {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t a {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-top: 18px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-top: 16px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-top: 14px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-top: 12px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-left: 46px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-left: 42px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-left: 38px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-left: 34px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-left: 30px;
  }
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-right: 46px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-right: 42px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-right: 38px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-right: 34px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t a {
    padding-right: 30px;
  }
}

.index-about .about-cont .cont-text .text-t a::after {
  content: '';
  height: 1px;
  display: block;
  background-color: #000;
  position: absolute;
  top: 50%;
  left: 87%;
  transition: all .3s;
  width: 50px;
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t a::after {
    width: 46px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t a::after {
    width: 42px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t a::after {
    width: 38px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t a::after {
    width: 34px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t a::after {
    width: 30px;
  }
}

.index-about .about-cont .cont-text .text-t a:hover {
  background-color: #000;
  color: #fabe00;
}

.index-about .about-cont .cont-text .text-t a:hover::after {
  background-color: #fabe00;
  left: 90%;
  width: 60px;
}

@media (max-width: 1600px) {
  .index-about .about-cont .cont-text .text-t a:hover::after {
    width: 56px;
  }
}

@media (max-width: 1366px) {
  .index-about .about-cont .cont-text .text-t a:hover::after {
    width: 52px;
  }
}

@media (max-width: 1024px) {
  .index-about .about-cont .cont-text .text-t a:hover::after {
    width: 48px;
  }
}

@media (max-width: 991px) {
  .index-about .about-cont .cont-text .text-t a:hover::after {
    width: 44px;
  }
}

@media (max-width: 767px) {
  .index-about .about-cont .cont-text .text-t a:hover::after {
    width: 40px;
  }
}
