@import "../../assets/styles/utils";

.index-body{
    header .header-placeholder{
        height: 0;
    }
}

//半屏banner
.index-banner{
    .swiper-pagination-bullet:only-child {
        display: block!important;
    }
    overflow: hidden;
    box-sizing: border-box;
    background-color: $color-main;
    @include res(padding-top,$header-height-base,$header-height-ratio);
    @include res(height,100vh,(md:500px,sm:400px,xs:260px));
    .banner-cont{
        height: 100%;
        position: relative;
        .swiper-container{
            height: 100%;
            overflow: hidden;
            @include res(width,76.82%,(xs:100%));
            .swiper-slide{
                overflow: hidden;
                &.swiper-slide-active,&.swiper-slide-duplicate-active{
                    .slide-inner{
                        b{
                            transform: scale(1);
                        }
                    }
                }
                .slide-inner{
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    // @include res(width,76.82vw);
                    b{
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        position: absolute;
                        top: 0;
                        left: 0;
                        // transform: scale(1.2);
                        transition: all .8s;
                        transition-delay: .2s;
                    }
                    .video{
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        video{
                            width: auto;
                            @include res(height,140%);
                        }
                    }
                    &::after{
                        content: '';
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,.5);
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
        .swiper-pagination{
            height: 100%;
            top:0;
            bottom: auto;
            left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @include res(width,26px,(xs:10px));
            @include res(right,40px,(xs:10px));
            &::after{
                content: '';
                width: 1px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 12px;
                z-index: -1;
                @include res(display,block,(xs:none));
                @include res(left,12px,(xs:4px));
                @include res(background-color,#000,(xs:$color-main));
            }
            .swiper-pagination-bullet{
                opacity: 1;
                background: $color-main;
                // border: 1px solid #0d0a00;
                position: relative;
                box-sizing: border-box;
                margin-left: 1px;
                @include res(width,26px,(xs:8px));
                @include res(height,26px,(xs:8px));
                @include res(border,1px solid #0d0a00,(xs:1px solid $color-main));
                &:not(:last-child){
                    @include res(margin-bottom,28px, 8 / 28);
                }
                &:hover{
                    &::after{
                        transform: scale(1.6);
                    }
                }
                &::after{
                    content: '';
                    display: block;
                    width: 6px;
                    height: 6px;
                    background: #0d0a00;
                    border-radius: 50%;
                    position: absolute;
                    top: calc(50% - 3px);
                    left: calc(50% - 3px);
                    transition: all .3s;
                }
                &.swiper-pagination-bullet-active{
                    &::after{
                        @include res(background,#0d0a00,(xs:$color-main));
                    }
                    svg{
                        circle{
                            animation:6s circle linear;
                        }
                    }
                }
                svg{
                    width: 28px;
                    height: 28px;
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    z-index: 1;
                    transform-origin: center;
                    transform-box: fill-box;
                    transform: rotate(-90deg);
                    @include res(display,null,(xs:none));
                    circle {
                        stroke-width: 3;
                        fill: none;
                        stroke: #000;
                        stroke-dasharray: 76px;
                        stroke-dashoffset: 76px;
                    }
                }
            }
        }
        .index-banner-text{
            height: 0;
            width: 100%;
            position: absolute;
            top: 50%;
            right: 0;
            z-index: 2;
            text-align: right;
            .text-item{
                position: absolute;
                top: 0;
                right: 0;
                transform: translateY(-50%);
                width: 100%;
                .title1{
                    font-family: 'lato-black';
                    position: relative;
                    white-space: nowrap;
                    font-weight: bold;
                    @include res(font-size,80px, 30 / 80);
                    .title-yellow{
                        color: $color-main;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        @include res(padding-right,15%,12 / 15);
                        a{
                            display: flex;
                            align-items: center;
                            @include res(margin-right,35px, 10 / 35);
                            .iconfont{
                                @include res(font-size,80px, 26 / 80);
                            }
                        }
                    }
                    .title-black{
                        color: #000;
                        position: absolute;
                        top: 0;
                        right: 0;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        overflow: hidden;
                        @include res(width,23.18%,(xs:0));
                        @include res(padding-right,15%,12 / 15);
                    }
                }
                .title2{
                    color: #fff;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    @include res(font-size,70px, 20 / 70);
                    @include res(padding-right,160px, 50 / 160);
                }
            }
        }
    }

}

//全屏banner
// .index-banner{
//     overflow: hidden;
//     box-sizing: border-box;
//     background-color: $color-main;
//     @include res(padding-top,$header-height-base,$header-height-ratio);
//     @include res(height,100vh,(md:500px,sm:400px,xs:260px));
//     .banner-cont{
//         height: 100%;
//         position: relative;
//         .swiper-container{
//             height: 100%;
//             overflow: hidden;
//             @include res(width,100%);
//             .swiper-slide{
//                 overflow: hidden;
//                 &.swiper-slide-active,&.swiper-slide-duplicate-active{
//                     .slide-inner{
//                         b{
//                             transform: scale(1);
//                         }
//                     }
//                 }
//                 .slide-inner{
//                     position: absolute;
//                     height: 100%;
//                     width: 100%;
//                     left: 0;
//                     top: 0;
//                     b{
//                         display: block;
//                         width: 100%;
//                         height: 100%;
//                         background-position: center;
//                         background-repeat: no-repeat;
//                         background-size: cover;
//                         position: absolute;
//                         top: 0;
//                         left: 0;
//                         transform: scale(1.2);
//                         transition: all .8s;
//                         transition-delay: .2s;
//                         z-index: 0;
//                         // opacity: 0;
//                     }
//                     .video{
//                         width: 100%;
//                         height: 100%;
//                         top: 0;
//                         left: 0;
//                         z-index: 1;
//                         display: flex;
//                         align-items: center;
//                         justify-content: center;
//                         video{
//                             width: auto;
//                             @include res(height,140%);
//                         }
//                     }
//                     // &::after{
//                     //     content: '';
//                     //     display: block;
//                     //     width: 100%;
//                     //     height: 100%;
//                     //     background-color: rgba(0,0,0,.5);
//                     //     position: absolute;
//                     //     top: 0;
//                     //     left: 0;
//                     //     z-index: 2;
//                     // }
//                 }
//             }
//         }
//         .swiper-pagination{
//             height: 100%;
//             top:0;
//             bottom: auto;
//             left: auto;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             flex-direction: column;
//             @include res(width,26px,(xs:10px));
//             @include res(right,40px,(xs:10px));
//             .swiper-pagination-bullet{
//                 opacity: 1;
//                 background: none;
//                 border: 1px solid $color-main;
//                 position: relative;
//                 box-sizing: border-box;
//                 margin-left: 1px;
//                 @include res(width,26px,(xs:10px));
//                 @include res(height,26px,(xs:10px));
//                 &:not(:last-child){
//                     @include res(margin-bottom,28px, 8 / 28);
//                 }
//                 &::after{
//                     content: '';
//                     display: block;
//                     width: 6px;
//                     height: 6px;
//                     background: $color-main;
//                     border-radius: 50%;
//                     position: absolute;
//                     top: calc(50% - 3px);
//                     left: calc(50% - 3px);
//                 }
//                 &.swiper-pagination-bullet-active{
//                     @include res(background,null,(xs:$color-main));
//                     svg{
//                         circle{
//                             animation:6s circle linear;
//                         }
//                     }
//                 }
//                 svg{
//                     width: 28px;
//                     height: 28px;
//                     position: absolute;
//                     top: -3px;
//                     left: -3px;
//                     z-index: 1;
//                     transform-origin: center;
//                     transform-box: fill-box;
//                     transform: rotate(-90deg);
//                     @include res(display,null,(xs:none));
//                     circle {
//                         stroke-width: 3;
//                         fill: none;
//                         stroke: $color-main;
//                         stroke-dasharray: 76px;
//                         stroke-dashoffset: 76px;
//                     }
//                 }
//             }
//         }
//         .index-banner-text{
//             height: 0;
//             width: 100%;
//             position: absolute;
//             top: 50%;
//             right: 0;
//             z-index: 2;
//             text-align: left;
//             .text-item{
//                 position: absolute;
//                 top: 0;
//                 transform: translateY(-50%);
//                 display: none;
//                 @include res(left,160px, 20 / 160);
//                 .title1{
//                     font-family: 'lato-black';
//                     position: relative;
//                     @include res(font-size,120px, 36 / 120);
//                     .title-yellow{
//                         color: $color-main;
//                         display: flex;
//                         align-items: center;
//                         @include res(padding-right,160px, 50 / 160);
//                         a{
//                             display: flex;
//                             align-items: center;
//                             @include res(margin-left,35px, 10 / 35);
//                             .iconfont{
//                                 @include res(font-size,80px, 26 / 80);
//                             }
//                         }
//                     }
//                 }
//                 .title2{
//                     color: #fff;
//                     font-weight: bold;
//                     @include res(font-size,70px, 20 / 70);
//                     @include res(padding-right,160px, 50 / 160);
//                 }
//             }
//         }
//     }
// }

//圆形进度条
@keyframes circle {
  0% {
    stroke-dashoffset: 76px;
  }
  50% {
    stroke-dashoffset: 38px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}

.index-common-title{
    color: #000;
    font-family: 'lato-black';
    @include res(font-size,60px, 30 / 60);
    @include res(margin-top,110px, 20 / 110);
    @include res(margin-bottom,60px, 20 / 60);
}

.index-product{
    .product-tabs{
        display: flex;
        align-items: center;
        white-space: nowrap;
        justify-content: flex-start;
        overflow-x: auto;
        font-weight: bold;
        color: #515151;
        width: 100%;
        @include res(margin-bottom,20px, 0 / 20);
        @include res(font-size,20px, 16 / 20);
        @include res(padding-bottom,20px, 5 / 20);
        @include res(margin-top,0,(xs:-15px));
        .tab-item{
            cursor: pointer;
            transition: all .25s;
            display: flex;
            align-items: center;
            @include res(margin-right,10px, 5 / 10);
            @include res(padding-top,10px, 0 / 10);
            &:not(:first-child){
                @include res(margin-left,30px, 5 / 30);
            }
            &:not(.active){
                &:hover{
                    color: $color-main;
                }
            }
            &::before{
                font-family: 'iconfont';
                font-weight: lighter;
                color: #c0c0c0;
                transition: all;
                @include res(margin-right,5px, 2 / 5);
                @include res(font-size,40px, 18 / 40);
            }
            &:nth-child(1)::before{
                content: '\e603';
            }
            &:nth-child(2)::before{
                content: '\e608';
            }
            &:nth-child(3)::before{
                content: '\e60a';
            }
            &:nth-child(4)::before{
                content: '\e60b';
            }
            &:nth-child(5)::before{
                content: '\e604';
            }
            &:nth-child(6)::before{
                content: '\e60d';
            }
            span{
                position: relative;
                display: block;
                @include res(padding-top,10px, 10 / 10);
                @include res(padding-bottom,10px, 10 / 10);
                &::after{
                    content: '';
                    display: block;
                    width: 0;
                    background-color: $color-main;
                    position: absolute;
                    bottom: 10px;
                    left: 0;
                    z-index: -1;
                    transition: all .3s;
                    @include res(height,10px,5 / 10);
                }
            }

            &.active{
                &::before{
                    color: $color-main;
                }
                span{
                    &::after{
                        width: 100%;
                    }
                }
            }
        }
        &.cn-tabs{
           .tab-item{
                // &:nth-child(1)::before{
                //     content: '\e603';
                // }
                &:nth-child(1)::before{
                    content: '\e608';
                }
                &:nth-child(2)::before{
                    content: '\e60a';
                }
                &:nth-child(3)::before{
                    content: '\e60b';
                }
                &:nth-child(4)::before{
                    content: '\e604';
                }
                &:nth-child(5)::before{
                    content: '\e60d';
                }
            }
        }
    }
    .product-swiper{
        margin-left: -20px;
        margin-right: -20px;
        .swiper{
            padding: 20px;
            @include res(padding-top,20px,(xs:10px));
            @include res(padding-bottom,100px,(md:50px,sm:30px,xs:10px));
        }

    }
}

.index-search{
    background-color: $color-main;
    position: relative;
    .fixed-img{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 80%;
        height: 138px;
        z-index: 0;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        overflow: hidden;
        img{
            display: block;
            max-width: 100%;
            width: auto;
            height: auto;
        }
    }
    .container{
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;
        @include res(display,flex,(xs:block));
        .search-left{
            flex-shrink: 0;
            box-sizing: border-box;
            @include res(width,65%,(md:48%,xs:100%));
            @include res(padding-top,120px, 20 / 120);
            @include res(padding-bottom,110px, 50 / 110);
            .form-title{
                font-family: 'lato-black';
                @include res(font-size,32px,16 / 32);
                @include res(margin-bottom,25px, 15 / 25);
            }
            .form-radio{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                white-space: nowrap;
                flex-wrap: wrap;
                @include res(margin-bottom,30px, 10 / 30);
                label{
                    display: inline-block;
                    position: relative;
                    cursor: pointer;
                    flex-shrink: 0;
                    @include res(width,16%,(mmd:25%,md:33.33%,xs:50%));
                    // @include res(margin-right,40px, 20 / 40);
                    @include res(margin-bottom,20px, 10 / 20);
                    input{
                        opacity: 0;
                        @include res(margin-right,15px,(sm:10px));
                    }
                    span{
                        &::before{
                            content: '';
                            display: block;
                            border-radius: 50%;
                            border: 1px solid #000000;
                            position: absolute;
                            left: 0;
                            @include res(width,20px,(sm:16px));
                            @include res(height,20px,(sm:16px));
                            @include res(top,calc(50% - 10px),(sm:calc(50% - 8px)));
                        }
                        &::after{
                            content: '';
                            display: block;
                            border-radius: 50%;
                            background-color: #000;
                            position: absolute;
                            left: 4px;
                            opacity: 0;
                            transition: all .25s;
                            @include res(width,12px,(sm:8px));
                            @include res(height,12px,(sm:8px));
                            @include res(top,calc(50% - 6px),(sm:calc(50% - 4px)));
                        }
                    }
                    input:checked + span{
                        &::after{
                            opacity: 1;
                        }
                    }
                }
            }
            .form-btn{
                .search-btn{
                    display: inline-block;
                    background-color: #000;
                    color: $color-main;
                    cursor: pointer;
                    border: none;
                    outline: none;
                    transition: all .3s;
                    border: 1px solid #000;
                    font-family: 'lato-black';
                    @include res(font-size,20px, 16 / 20);
                    @include res(padding-top,20px, 10 / 20);
                    @include res(padding-bottom,20px, 10 / 20);
                    @include res(padding-left,85px, 30 / 85);
                    @include res(padding-right,85px, 30 / 85);
                    &:hover{
                        background-color: $color-main;
                        color: #000;
                    }
                }
            }
        }
        .search-right{
            flex-shrink: 0;
            position: relative;
            @include res(margin-top,-250px,(lg:-210px,mmd:-180px,md:-160px,sm:-100px,xs:0px));
            @include res(width,33.33%,(md:50%,xs:100%));
            .bg-img{
                position: absolute;
                top: 0;
                height: 100%;
                @include res(width,130%);
                @include res(left,0,(xs:-15%));
                &::after{
                    content: '';
                    display: block;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    background-color: rgba(0,0,0,.68);
                }
                b{
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
            .cont{
                display: block;
                position: relative;
                z-index: 2;
                color: $color-main;
                @include res(padding-top,180px, 60 / 180);
                @include res(padding-bottom,30px, 10 / 30);
                @include res(padding-left,95px, 20 / 95);
                .text{
                    line-height: .95;
                    &::before{
                        content: '';
                        display: block;
                        background-color: $color-main;
                        @include res(width,100px, 50 / 100);
                        @include res(height,4px, 2 / 4);
                        @include res(margin-bottom,45px, 20 / 45);
                    }
                    h1{
                        font-family: 'lato-black';
                        @include res(font-size,46px, 22 / 46);
                    }
                    h2{
                        font-family: 'lato-black';
                        @include res(font-size,60px, 30 / 60);
                    }
                }
                .icon{
                    display: flex;
                    justify-content: flex-end;
                    @include res(margin-top,60px, 20 / 60);
                    .iconfont{
                        @include res(font-size,170px,80 / 170);
                        @include res(transform, translateX(30%),(lg:translateX(20%),mmd:translateX(10%),md:translateX(0)));
                    }
                }
            }
        }
    }
}

.index-about{
    position: relative;
    overflow: hidden;
    @include res(padding-top,80px, 10 / 80);
    @include res(padding-bottom,130px, 30 / 130);
    .fixed-img{
        position: absolute;
        top: 0;
        right: 0;
        width: 80%;
        height: 138px;
        z-index: 0;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        // overflow: hidden;
        transform: translateY(-100%);
        img{
            display: block;
            max-width: 100%;
            width: auto;
            height: auto;

        }
    }
    .about-cont{
        align-items: center;
        justify-content: space-between;
        @include res(display,flex,(xs:block));
        .cont-img{
            flex-shrink: 0;
            position: relative;
            overflow: hidden;
            @include res(width,40%,(xs:100%));
            @include res(margin-bottom,0,(xs:20px));
            b{
                display: block;
                width: 100%;
                height: 200%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                position: absolute;
                top: -70%;
                left:0;
                z-index: 0;
            }
            .img-text{
                position: relative;
                z-index: 1;
                background: rgba(0,0,0,.2);
                @include res(padding-top,45px, 20 / 45);
                @include res(padding-bottom,45px, 20 / 45);
                @include res(padding-left,100px, 10 / 100);
                @include res(padding-right,10px, 10 / 10);
                .item{
                    color: #fff;
                    @include res(text-align,null,(xs:center));
                    &:not(:last-child){
                        @include res(margin-bottom,36px, 20 / 36);
                    }
                    h1{
                        font-family: 'lato-black';
                        @include res(font-size,80px, 40 / 80);
                    }
                    p{
                        font-weight: bold;
                    }
                }
            }
        }
        .cont-text{
            flex-shrink: 0;
            @include res(width,50%,(lg:55%,xs:100%));
            .text-title{
                font-weight: bold;
                @include res(font-size,56px, 22 / 56);
                @include res(margin-bottom,60px, 20 / 60);
            }
            .text-t{
                @include res(padding-left,105px, 10 / 105);
                h1{
                    color: $color-main;
                    font-weight: bold;
                    position: relative;
                    font-style: italic;
                    @include res(margin-bottom,35px, 15 / 35);
                    @include res(font-size,50px, 25 / 50);
                    @include res(padding-left,50px, 30 / 50);
                    img{
                        height: auto;
                        @include res(width,34px, 20 / 34);
                        &.left-yh{
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                        &.right-yh{
                            transform: rotate(180deg);
                            @include res(margin-left,22px, 10 / 22);
                        }
                    }
                }
                .t{
                    @include res(text-align,justify,(xs:left));
                    text-justify: inter-ideograph;/*IE*/
                    @include res(line-height,2, 1.6 / 2);
                    @include res(margin-bottom,70px, 20 / 70);
                }
                a{
                    display: inline-block;
                    background-color: $color-main;
                    position: relative;
                    transition: all .3s;
                    @include res(font-size,18px, 16 / 18);
                    @include res(padding-top,20px, 10 / 20);
                    @include res(padding-bottom,20px, 10 / 20);
                    @include res(padding-left,50px, 30 / 50);
                    @include res(padding-right,50px, 30 / 50);
                    &::after{
                        content: '';
                        height: 1px;
                        display: block;
                        background-color: #000;
                        position: absolute;
                        top: 50%;
                        left: 87%;
                        transition: all .3s;
                        @include res(width,50px, 30 / 50);
                    }
                    &:hover{
                        background-color: #000;
                        color: $color-main;
                        &::after{
                            background-color: $color-main;
                            left: 90%;
                            @include res(width,60px, 40 / 60);
                        }
                    }
                }
            }
        }
    }
}
